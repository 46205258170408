@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Sarabun-Regular.ttf');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Sarabun-Bold.ttf');
}
@font-face {
  font-family: 'Sarabun';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Sarabun-Bold.ttf');
}
button:focus{
outline: none !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(68,73,102,0.1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(68,73,102,0.1);
}

body {
  font-family: 'Sarabun' !important;
}
.react-calendar{
  font-family: 'Sarabun' !important;
}

.btn{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 13px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:focus{
  box-shadow: none  !important;
  outline: none !important;
}
.btn-bule-dark{
  border-color: #444966;
  background-color: #444966;
  color: #ffffff;
  margin: 0 15px;
}
.btn-light-gray{
  border-color: #F2F2F2;
  background-color: #F2F2F2;
  color: #666666;
  margin: 0 15px;
}
.width-btn{
  width: 100px;
}
.MuiDialogActions-root{
  justify-content: center !important;
  padding: 16px 24px 24px 24px !important;
}
.MuiDialog-root{
  z-index: 99999999999 !important;
}
.MuiDialog-paperWidthSm{
  min-width: 420px;
  box-sizing: content-box;
  border-radius: 23px!important;
}
.MuiTypography-colorTextSecondary{
  text-align: center;
  color: #000000!important;
  font-weight: bold!important;
  font-size: 22px!important;
}
.MuiTypography-h6{
  text-align: center;
  line-height: 80px!important;
  font-size: 80px!important;
  color: #E54363;
}
.react-date-picker__button:before{
  font-family: "fontello";
  content: '\E801';
  font-size: 19px;
  padding:  0;
}
.react-date-picker__button:enabled{
  padding: 5px;
  border-color: transparent;
}
.wrap-maker{
  position: relative;
}
.user-maker{
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 30px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #444966;
}
.maker{
  font-size: 60px;
  line-height: 60px;
  color: #444966;
}
::-webkit-input-placeholder { /* Edge */
  color: rgba(68,73,102,0.5)
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(68,73,102,0.5)
}

::placeholder {
  color: rgba(68,73,102,0.5)
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: block;
  max-height: 7rem;
  overflow-y: scroll;
  /* padding-bottom: 35px; */
}
.react-tags__selected-tag:last-child{
  margin-bottom: 35px;
}
.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;
  position: relative;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 2px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.react-tags.react-tags.is-focused{
  border: 1px solid rgba(68,73,102,0.1);
  border-radius: 7px;
  -webkit-transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}
.react-tags{
  /* padding: 0; */
  /* border: 0; */
  padding: 5px 6px;
  background-color: #fff;
  background-clip: padding-box;
  box-sizing: border-box;
  border: 1px solid rgba(68,73,102,0.1);
  border-radius: 7px;
  -webkit-transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}
.react-tags__search{
  margin-bottom: 0;
  width: 100%;
  padding-right: 15px;
}
.react-tags__search::after{
  font-family: "fontello";
  content: "\E806";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  padding: 0;
  color:#C4C4C4;
  position: absolute;
  right: 20px;
  top: 2px;
}
.react-tags__search input{
    width: 100%;
    /* padding: 5px 30px 5px 6px; */
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #242D31;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid rgba(68,73,102,0.1); */
    border-radius: 7px;
    /* -webkit-transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; */
    /* transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out; */
    box-sizing: border-box;
    margin-bottom: 0;
    /* background-image: url(../images/search.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain; */
    }
    .react-tags__selected-tag{
      font-family: 'Sarabun'!important;
      font-size: 14px!important;
      color: #242D31 !important;
      background: #E5E5E5!important;
      border-radius: 7px!important;
      border-color: #E5E5E5!important;
      padding: 3px 20px 3px 5px!important;
    }
    .react-tags__selected-tag-name{
      font-weight: 400 !important;
    }
    .react-tags__selected-tag:after{
      font-family: "fontello";
      content: "\E807";
      display: inline-block;
      vertical-align: middle;
      font-weight: 900;
      color: #242D31 !important;
      font-size: 16px!important;
      background-color: #ffffff!important;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      right: 3px;
      top: 2px;
    }
    .react-tags__suggestions ul{
      padding: 15px!important;
    background-color: #ffffff!important;
    border: 1px solid #ECEDF0!important;
    box-sizing: border-box!important;
    border-radius: 7px!important;
    max-height: 16rem;
    overflow-y: scroll;
    }
    .react-tags__suggestions li{
      border-bottom: 0!important;
      padding: 10px 8px!important;
      border-radius: 10px!important;
    }
    .react-tags__suggestions li:hover{
      background: #FDB513!important;
    }
    .react-tags__suggestions li span{
      font-size: 14px!important;
      color: #242D31!important;
      font-weight: 400!important;
    }
    @media screen and (min-width: 30em)
{
  .react-tags__suggestions {
    width: 99%;
    left: -7px;
    top: 36px;
    z-index: 99;
  }
}

 